<template>
  <div class="hero">
    <b-container>
      <b-row class="mt-1">
        <b-col cols="12" md="6" offset-md="3" class="text-center">
          <h2 class="text-primary">Annual Meeting</h2>
          <p class="h5">Monday 15th April 2024 at 7PM</p>
          <p>On Monday 15th April 2024 at 7PM the Car Scheme will be hosting it's annual meeting.</p>
          <b-btn class="px-4 py-2" variant="primary" target="_blank" href="/files/PosterApril2024.pdf">Click here for more details</b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Announcement",
  data: () => ({
    publicPath: ''
  }),
  beforeMount() {
    this.publicPath = process.env.BASE_URL;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.news-image {
  height: 231px;
  width: 100%;
  object-fit: cover;
}

.btn-announcement {
  color: #fff;
  background-color: #1a7e92;
  border-color: #1a7e92;
}

.custom-link:link {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

.custom-link:visited {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

.custom-link:hover {
  color: #0a6e82;
  background-color: transparent;
  text-decoration: none;
}

.custom-link:active {
  color: #0a6e82;
  background-color: transparent;
  text-decoration: none;
}</style>
