<template>
  <div class="hero top-hero">
    <b-container>
      <b-row>
        <b-col cols="12" md="7" class="text-secondary">
          <h1 >Stuck for transport?</h1>
          <h2 class="mt-5">
            Why not register with the TH&amp;W Voluntary Car Scheme
          </h2>
          <p class="h3 mt-4">
            ... it's open to all adult residents of Thorney, Harby, Wigsley,
            North &amp; South Clifton and Spalford
          </p>
        </b-col>
        <b-col cols="12" md="5">
          <b-img-lazy
            class="lotto-image float-right"
            alt="Big Lottery Fund logo"
            src="../../assets/big-lottery-fund.png"
          />
          <b-img-lazy
            class="mw-100 mt-3"
            alt="Pink car"
            src="../../assets/pink-car.png"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "TopHero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-hero {
  background-image: linear-gradient(180deg, #359eb2 0%, #1a7e92 100%);
}
.lotto-image {
  max-width: 115px;
}
</style>
