<template>
  <div class="my-n5" data-aos="fade-up">
      <top-hero />
      <announcement/>
      <want-to-know-more />
      <get-on-the-move />
  </div>
</template>

<script>
import TopHero from './TopHero.vue';
import WantToKnowMore from './WantToKnowMore.vue'
import GetOnTheMove from './GetOnTheMove.vue'
import Announcement from './Announcement.vue'
export default {
  name: "Home",
  components: {
    TopHero,
    GetOnTheMove,
    WantToKnowMore,
    Announcement
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
