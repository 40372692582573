<template>
  <div class="hero">
    <b-container>
      <h2 class="text-primary text-center">Get On The Move</h2>
      <b-row class="mt-5">
        <b-col cols="12" md="6">
          <p>
            Thorney, Harby and Wigsley Volunteer Car Scheme is a community
            transport service to enable adult residents of all ages to access
            health related services, shopping amenities, leisure &amp; social
            activities during the day or evenings. Trips are just 45p per mile
            &amp; must be booked in advance. To use the scheme you need to
            register in advance. To get more information about becoming a
            volunteer driver please see our <b-link to="/drivers">drivers</b-link> page or <b-link to="contact-us">get in touch</b-link>.
          </p>
        </b-col>
        <b-col cols="12" md="6">
          <b-img-lazy
            class="w-100"
            fluid
            alt="One of the drivers helping a passenger get out of a car."
            src="../../assets/get-on-the-move.jpg"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "GetOnTheMove",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
