<template>
  <div class="bg-secondary hero">
    <b-container class="text-center">
      <b-row>
        <b-col cols="12" md="6" class="mt-2 mb-4 mb-md-2">
            <h2 class="text-primary">Want to know more?</h2>
            <p>If you would like more information about becoming a volunteer driver it would be great to hear from you.</p>
            <b-btn class="px-4 py-2" variant="primary" to="/drivers">Find Out More</b-btn>
        </b-col>
        <b-col cols="12" md="6" class="mb-2 mt-4 mt-md-2">
            <h2 class="text-primary">Register today!</h2>
            <p>To be part of the scheme as a passenger or a volunteer driver you need to register in advance – this is FREE OF CHARGE!</p>
            <b-btn class="px-4 py-2" variant="primary" to="/about-thaw">Find Out More</b-btn>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "WantToKnowMore",
};
</script>
